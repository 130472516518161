<template>
    <div class="app flex-row align-items-center">
        <div class="container">
            <b-row class="justify-content-center">
                <b-col md="6">
                    <div class="clearfix">
                        <h1 class="float-left display-3 mr-4">404</h1>
                        <h4 class="pt-3">Pagina niet gevonden.</h4>
                        <p class="text-muted">De pagina waar je naar opzoek was bestaat niet of is verwijderd.</p>
                    </div>
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-input-group-text>
                                <i class="fa fa-search"></i>
                            </b-input-group-text>
                        </b-input-group-prepend>
                        <input id="prependedInput" class="form-control" size="16" type="text" placeholder="Waar zoek je naar?">
                        <b-input-group-append>
                            <b-button variant="primary">Search</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    export default {
        name: '404'
    }
</script>
